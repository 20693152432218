<template>
  <div id="overlay">
    <div class="info-wrapper">
      <h5 class="mt-5">It seems your permissions have been changed.</h5>

      <p class="mt-5">You have to login again to continue.</p>

      <vs-button icon-pack="feather" class="mt-5" @click="goToLogin"
        >Go To Login Page</vs-button
      >
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  methods: {
    goToLogin() {
      // if user is logged in via auth0
      //if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      // If JWT login
      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        )
      ) {
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );

        this.$cookies.remove(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        window.location = "/login";
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem("userInfo");
      window.location = "/login";
    },
  },
};
</script>

<style scoped>
.info-wrapper {
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  cursor: pointer;
}
</style>
